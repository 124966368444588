<template>
  <App title="Create order" left-arrow>
    <div class="container">
      <Steps :value="curStep" :stepList="stepList" />
      <keep-alive>
        <VehicleModel
          v-if="curStep === 0"
          ref="vehicleModel"
          :carTypeCode="carType.carTypeCode"
          :featureCodeList="featureList.map((item) => item.featureCode)"
          @on-price-change="(price) => (this.totalPrice = price)"
        />
        <div v-else>
          <OrderModel
            ref="orderModel"
            :carType="carType"
            :customerInfo="customerInfo"
            :carSeriesInfo="carSeriesInfo"
            :vehicleInfo="vehicleInfo"
            @on-discount-change="(discount) => (this.discount = discount)"
          />
        </div>
      </keep-alive>
    </div>
    <Footer
      slot="footer"
      :step="curStep"
      :maxStep="stepList.length - 1"
      :totalPrice="totalPrice"
      :discount="discount"
      @on-last="lastStep"
      @on-next="nextStep"
      @on-save="onSave"
      @on-cancel="onCancel"
    />
  </App>
</template>
<script>
import { Toast } from "vant";
import Steps from "./components/steps";
import Footer from "./components/footer";
import VehicleModel from "_c/business/vehicleModel";
import OrderModel from "./components/orderModel";
import * as orderApi from "@/api/order";
import jsBridge from "../../jsBridge/jsBridge";

export default {
  name: "createQuoteOrder",
  components: {
    Steps,
    Footer,
    VehicleModel,
    OrderModel,
  },
  data() {
    return {
      leadsId: "",
      loading: false,
      showKeyboard: false,
      curStep: 0,
      stepList: [{ label: "Model" }, { label: "Order" }],
      totalPrice: 0,
      discount: 0,
      customerInfo: {}, // 当前客户详情
      carSeriesInfo: {}, // 当前工程车型
      intentionAmount: "", // DR意向金
      carType: {}, // 所选基础车型信息
      vehicleInfo: {},
      featureList: [], // 所选车型配置
      outColorList: [], // 所选外色数据
    };
  },
  computed: {
    channel() {
      return this.$storage.get("channel");
    },
  },
  watch: {
    loading(value) {
      if (value) {
        /* Toast.loading({
          duration: 0,
          message: "Loading...",
          loadingType: "spinner",
          forbidClick: true,
        }); */
      } else {
        Toast.clear();
      }
    },
  },
  methods: {
    // 提交
    async onSave() {
      this.loading = true;
      const data = await this.$refs.orderModel.validate();
      const price =
        +this.vehicleInfo.showCarPrice +
        +this.vehicleInfo.showCarDeposit +
        +this.vehicleInfo.showIntentionMoney;
      const discountedPrice =
        price - (+data.campaign || 0) - (+data.dealer || 0);
      const obj = {
        name: this.customerInfo.name,
        contact: this.customerInfo.phone,
        model: this.carType.carTypeCn.split(" ")[0],
        specification: this.carType.carTypeCn.split(" ")[1],
        powertrain: this.featureList.find((e) => e.faimlyCode === "PKG")
          .featureName,
        colour: this.featureList.find((e) => e.faimlyCode === "BAC")
          .featureName,
        price: price.toFixed(2),
        campaign: data.campaign,
        dealer: data.dealer,
        tax: (discountedPrice * 0.1).toFixed(2),
        total: (discountedPrice + discountedPrice * 0.1).toFixed(2),
      };
      orderApi
        .leadsPriceOpp({
          type: 0,
          leadsId: this.leadsId,
          shareData: JSON.stringify(obj),
        })
        .then((res) => {
          jsBridge.share(
            `We got an exclusive discount for you. Click the link for the quote details: ${
              document.location.origin + "/#/Quote?id=" + res.data.id
            }`
          );
          // this.$router.push({
          //   path: "/Quote",
          //   query: {
          //     id: res.data.id,
          //   },
          // });
        })
        .finally(() => (this.loading = true));
    },
    // 取消
    onCancel() {
      this.$router.go(-1);
    },
    // 上一步
    lastStep() {
      if (this.curStep > 0) {
        this.curStep--;
      }
    },
    // 下一步
    nextStep() {
      if (this.curStep < this.stepList.length - 1) {
        this.loading = true;
        this.$refs.vehicleModel
          .validate()
          .then((res) => {
            this.carSeriesCode = res.carSeriesCode;
            this.carSeriesInfo = res.carSeriesInfo;
            this.carType = res.carType;
            this.vehicleInfo = res.vehicleInfo;
            this.featureList = res.featureCodes;
            this.outColorList = res.outColorList;
            this.curStep++;
          })
          .catch((err) => {})
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    const pageParam = this.$route.query || {};
    const { leadsId, name, phone } = pageParam;
    this.leadsId = leadsId;
    this.customerInfo = { name, phone };
    if (this.curStep === 0) {
      this.$nextTick(() => {
        this.$refs.vehicleModel.init();
      });
    }
  },
};
</script>
<style lang="less" scoped>
/deep/#container {
  height: 100vh;
}
.container {
  position: relative;
  padding-top: 16px;
  padding-bottom: 24px;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}
</style>